import React from 'react'
import PropTypes from 'prop-types'
import '../scss/about-us-banner.scss'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import RenderGatsByImage from '../common/RenderGatsByImage'
import Word from './Word'

export default function AboutUsBanner(props) {
  const { aboutUsBanner } = props

  return (
    <React.Fragment>
      <div className="d-md-flex about-us-container">
        <div className="left-pane">
          <div className="text-banner">
            <div className="text-container">
              <div className="styled-text d-flex">
                {aboutUsBanner.title.map((item) => (
                  <Word {...item} />
                ))}
              </div>
              <div className="desc">{aboutUsBanner.description}</div>
              <div className="note-container align-items-center">
                <div className="note">
                  {aboutUsBanner.notes.map((item) => {
                    return (
                      <div className="mt-4" key={item.note}>
                        {item.note}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right-pane">
          <video
            src={aboutUsBanner.video.publicURL || aboutUsBanner.video.url}
            className="video-player"
            loop={true}
            autoPlay="autoPlay"
            id="vid"
            muted
          ></video>
        </div>
      </div>
    </React.Fragment>
  )
}

AboutUsBanner.propTypes = {
  height: PropTypes.number,
  aboutUsBanner: PropTypes.object
}
