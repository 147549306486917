import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import AboutUsBanner from '../components/koble/AboutUsBanner'
import OurTeam from '../components/koble/OurTeam'

// eslint-disable-next-line
export const AboutPageTemplate = ({ aboutUsBanner, ourTeam }) => {
  return (
    <div>
      <AboutUsBanner aboutUsBanner={aboutUsBanner} />
      <OurTeam ourTeam={ourTeam} />
    </div>
  )
}

AboutPageTemplate.propTypes = {
  aboutUsBanner: PropTypes.object,
  ourTeam: PropTypes.object
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <AboutPageTemplate
        aboutUsBanner={post.frontmatter.aboutUsBanner}
        ourTeam={post.frontmatter.ourTeam}
      />
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        aboutUsBanner {
          title {
            cssClass
            text
          }
          description
          notes {
            note
          }
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
        ourTeam {
          title {
            cssClass
            text
          }
          description
          teamMembers {
            image {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                )
              }
            }
            name
            designations {
              designation
            }
            descriptions {
              description
            }
          }
        }
      }
    }
  }
`
