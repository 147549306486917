import React from 'react'
import PropTypes from 'prop-types'
import '../scss/team-member.scss'
import { getImage } from 'gatsby-plugin-image'
import RenderGatsByImage from '../common/RenderGatsByImage'

export default function TeamMember(props) {
  const { teamMember, height = 770, imgPosition = 'top left' } = props
  const heroImage = getImage(teamMember.image) || teamMember.image
  return (
    <React.Fragment>
      <div className="tmb-container">
        <div className="row">
          <div className="col-12 col-md-3 d-flex align-items-center">
            <div className="tm-img">
              <div
                className="margin-top-0 flex-grow-1"
                style={{
                  display: 'grid',
                  alignItems: 'center'
                }}
              >
                <RenderGatsByImage imageSource={heroImage} width={172} />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-9 d-flex align-items-center">
            <div className="">
              <div className="d-flex align-items-center mb-3 flex-wrap pb-2 pb-md-0">
                <div className="tm-title me-3 w-m-100 mb-2 mb-md-0">
                  {teamMember.name}
                </div>
                <div className="d-flex align-items-center tm-designations">
                  {teamMember.designations.map((item) => {
                    return (
                      <div className="tm-designation" key={item.designation}>
                        {item.designation}
                      </div>
                    )
                  })}
                </div>
              </div>
              {teamMember.descriptions.map((item, index) => {
                const classNames =
                  index === teamMember.descriptions.length - 1
                    ? 'tm-description '
                    : 'tm-description mb-4'
                return (
                  <div className={classNames} key={index}>
                    {item.description}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

TeamMember.propTypes = {
  TeamMember: PropTypes.object
}
