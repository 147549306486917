import React from 'react'
import PropTypes from 'prop-types'
import '../scss/our-team.scss'
import TeamMember from './TeamMember'

export default function OurTeam(props) {
  const { ourTeam } = props

  const { teamMembers } = ourTeam

  return (
    <React.Fragment>
      <div className="ab-container">
        <div className="container">
          <div className="row ab-text">
            <div className="col-12 col-md-4">
              <div className="">
                <div className="d-flex align-items-center styled-text">
                  {ourTeam.title.map((item) => {
                    return (
                      <div key={item.text} className={item.cssClass}>
                        {item.text}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex justify-content-end ">
                <div className="ab-description">{ourTeam.description}</div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          {teamMembers.map((teamMember, index) => {
            return (
              <React.Fragment key={index}>
                <TeamMember teamMember={teamMember} />
                {index !== teamMembers.length - 1 && (
                  <div className="sub-divider" />
                )}
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </React.Fragment>
  )
}

OurTeam.propTypes = {
  ourTeam: PropTypes.object
}
